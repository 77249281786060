import React from "react";
import { Link } from "@remix-run/react";

type Props = {
  text: string;
  labelColor: string /* primaryが職種、secondaryが業態、tertiaryがエリア */;
  linkUrl: string;
};

const LabelLink: React.FC<Props> = (props) => {
  return (
    <Link
      to={props.linkUrl}
      className={`inline-block px-3 py-2.5 rounded-full text-xs leading-[1.35] font-bold md:py-1 md:text-sm md:leading-[1.8] ${props.labelColor} [&.primary]:bg-primary-light [&.primary]:text-primary hover:[&.primary]:bg-primary hover:[&.primary]:text-primary-light [&.secondary]:bg-secondary-light [&.secondary]:text-secondary hover:[&.secondary]:bg-secondary hover:[&.secondary]:text-secondary-light [&.tertiary]:bg-tertiary-light [&.tertiary]:text-tertiary hover:[&.tertiary]:bg-tertiary hover:[&.tertiary]:text-tertiary-light`}
    >
      {props.text}
    </Link>
  );
};

export default LabelLink;
