import React from "react";
import { Link } from "@remix-run/react";

type Props = {
  linkUrl: string;
  imageUrl: string;
  text: string;
};

const SearchItem: React.FC<Props> = (props) => {
  return (
    <Link to={props.linkUrl} className="group block rounded bg-primary">
      <figure className="relative aspect-[146/112] rounded overflow-hidden md:aspect-[195/150] group-hover:translate-x-[-4px] group-hover:translate-y-[-4px] before:absolute before:inset-0 before:z-10 before:bg-[linear-gradient(0deg,rgba(51,51,51,1)_0%,rgba(51,51,51,0)_50%,rgba(51,51,51,0)_100%)] before:mix-blend-multiply">
        <img
          className="relative object-cover object-center w-full h-full z-0"
          src={props.imageUrl}
          alt=""
        />
        <figcaption className="absolute bottom-0 left-0 w-full p-1.5 font-black text-white text-[13px] leading-[1.8] z-20 md:p-2 md:text-sm md:leading-[1.8]">
          {props.text}
        </figcaption>
      </figure>
    </Link>
  );
};

export default SearchItem;
