import SearchItem from "./SearchItem";
import TopHeading from "./TopHeading";
import SimpleBarWrap from "./SimpleBarWrap";
import type { ServiceType, Masters } from "~/types";

const SearchByBiz = ({ masters }: { masters: Masters }) => {
  const serviceTypes = masters.serviceTypes;

  // クエリパラメーターを生成
  const generateQueryParams = (serviceTypeIds: string) => {
    const params = new URLSearchParams();
    params.append("service_type_ids", serviceTypeIds);
    return params.toString();
  };

  return (
    <section className="my-20 md:my-[104px] md:px-7">
      <div className="md:max-w-[1040px] md:mx-auto">
        <div className="mb-6 sp:px-4 md:mb-8">
          <TopHeading
            text="業態から探す"
            icon="bowl"
            iconBg="secondary"
          ></TopHeading>
        </div>
        <SimpleBarWrap>
          <div className="grid gap-4 sp:grid-cols-[repeat(auto-fill,minmax(146px,1fr))] sp:w-[calc(((146px_+16px)_*_36)_+_16px)] sp:px-4 sp:pb-8 sp:[&_a]:flex-shrink-0 sp:[&_a]:w-[146px] md:grid-cols-[repeat(auto-fill,minmax(195px,1fr))]">
            {serviceTypes.map((serviceType: ServiceType) => (
              <SearchItem
                key={serviceType.id}
                linkUrl={`/list/?${generateQueryParams(serviceType.id)}`}
                imageUrl={`/top/biz/biz-${serviceType.key}.webp`}
                text={serviceType.name}
              ></SearchItem>
            ))}
          </div>
        </SimpleBarWrap>
      </div>
    </section>
  );
};

export default SearchByBiz;
