import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import TopPickUpItem from "./TopPickUpItem";

const TopPickUp = () => {
  return (
    <section className="my-14 md:mt-20 md:mb-[104px]">
      <div className="px-4 md:px-7">
        <div className="max-w-[1040px] mx-auto">
          <div className="flex items-center sp:justify-center">
            <svg className="block w-5 h-5 mr-1 fill-primary md:w-6 md:h-6">
              <use xlinkHref="/symbol-defs.svg#icon-egg"></use>
            </svg>
            <p className="text-primary text-sm font-candal tracking-[0.06em] md:text-lg md:tracking-[0.06em]">
              Pick Up
            </p>
          </div>
          <h2 className="mb-3 text-2xl text-center font-black leading-normal md:text-left md:text-[32px] md:leading-normal">
            ピックアップ求人
          </h2>
        </div>
      </div>
      <div id="pickupSlider">
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          breakpoints={{
            320: {
              slidesPerView: 1.3,
              spaceBetween: 16,
              centeredSlides: true,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/d3rJ2tbkkH1UZkq1MmrRZhuJf3J8JWhFKoZVMVYq5Mo=.html"
              company="株式会社フォンス"
              title="【年収480万円】渋谷の蕎麦ダイニング「酢重正之 酢重 Indigo」のホールスタッフ募集！マニュアルに捉われないホスピタリティを発揮できます！"
              payment="28万円〜40万円"
              location="東京都渋谷区"
              holiday="休日：月8日休み、休暇：有給休暇、リフレッシュ休暇（年2回×各4日）"
            >
              <li>ホール</li>
              <li>和食（割烹/懐石/会席）</li>
              <li>そば</li>
              <li>居酒屋</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/6rWkoUYzIk1pETDLF24pqYbI7UVxsAFgjI+6syr4kdY=.html"
              company="株式会社KICHIRI"
              title="【完全週休二日制】ららぽーと門真内の「いしがまやハンバーグ」の店長候補（総合職）！上場企業グループの成長・キャリアアップができる教育体制あり！"
              payment="20万円〜33万円"
              location="大阪府門真市"
              holiday="有給休暇（入社半年経過後10日～）、社員も土日休・連休取得が可能です、出産育児休暇、資格取得支援、慶弔休暇"
            >
              <li>店長</li>
              <li>イタリアンバル</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/MP6OrvamuZ0pgWXm7XLNbJ+whOd8eOdwlWHmrOY5s6E=.html"
              company="株式会社ファイブグループ"
              title="【年収860万円】吉祥寺本社・居酒屋業態の事業部長候補募集！働き甲斐のある会社ランキング・健康経営有料法人など受賞している企業です！"
              payment="62万円"
              location="東京都武蔵野市"
              holiday="公休：最大月9日、有給休暇：年間13日、春季休暇：3日、夏季休暇：3日、冬季休暇：3日、リフレッシュ休暇、アニバーサリー休暇、結婚休暇、育児休暇制度、その他、会社独自の休暇制度多数有り"
            >
              <li>営業部長</li>
              <li>焼き鳥（低単価）</li>
              <li>居酒屋</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/6upKfJn47hBcfA6dH23GZaI+dLFN14PueXZ70cJPjjY=.html"
              company="株式会社サンパーク"
              title="【年収550万円】行徳のカフェ「高木珈琲」の店長募集！FC本部としての事業拡大につきキャリアアップの可能性大！"
              payment="32万円〜39万円"
              location="千葉県市川市"
              holiday="週休2日制（シフト制／月8～9日）、7連休制度あり、慶弔休暇、有給休暇"
            >
              <li>AM（直営）</li>
              <li>カフェ（フルサービス）</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/nz6mGUFohwvMP9mtyZhrpwCVgrSKN2pUj3Sr3kRrePM=.html"
              company="常盤興産株式会社"
              title="【年収630万円】大阪ミナミの老舗鉄板ステーキ店「ニュー松坂」のキッチン（焼き手）募集！1店舗あたり６～７名の社員を配属・休みが取りやすい環境です！店舗毎のメニュー開発も可能です！"
              payment="30万円〜45万円"
              location="大阪府大阪市中央区"
              holiday="月8日休み、有給休暇"
            >
              <li>キッチン</li>
              <li>鉄板焼き（高単価・肉/魚）</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/FxWkC9Wirut1K3HO1C9A21mJ1xSgcUJfACwCeO8vNzQ=.html"
              company="常盤興産株式会社"
              title="【年収630万円】大阪ミナミの老舗鉄板ステーキ店「ニュー松坂」のサービス募集！1店舗あたり６～７名の社員を配属・休みが取りやすい環境です！出店計画も複数あり店長・マネージャーへのキャリアアップも可能です"
              payment="30万円〜45万円"
              location="大阪府大阪市中央区"
              holiday="月8日休み、有給休暇"
            >
              <li>ホール</li>
              <li>鉄板焼き（高単価・肉/魚）</li>
            </TopPickUpItem>
          </SwiperSlide>
{/*           <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/6vA3iqxc4HLa7ZwTIBI0YUPKSXzYluxphv0j403TVjU=.html"
              company="北野合同建物株式会社"
              title="【年間休日120日】永田町にあるラグジュアブティックホテル「ザ・キタノホテル東京」のサービススタッフ募集。リニューアル間もないホテルで良いホテルを作り上げる側で活躍して頂けます！"
              payment="20万円〜32万円"
              location="東京都千代田区"
              holiday="シフト制で取得、有給休暇（入社6か月後に付与、初年度10日）、慶弔休暇、産前産後休暇、育児休暇、介護休暇"
            >
              <li>ホール</li>
              <li>ホテル/旅館</li>
            </TopPickUpItem>
          </SwiperSlide> */}
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/DT7ySIWLrl4seLJ7FBIfmoW7TTA3rMXu7A1l9GU2Ygc=.html"
              company="株式会社 LATTE GRAPHIC"
              title="【年収480万円】町田・オセアニアスタイルのカフェ【LATTE GRAPHIC（ラテグラフィック）】の料理長募集！メニュー開発にも携わっていただきます！"
              payment="25.4万円〜40万円"
              location="東京都町田市"
              holiday="月6〜10日休　※月目安8日休み、元旦定休、有給休暇（年間5日間）、産休・育休（実績有り）"
            >
              <li>料理長</li>
              <li>フランス料理</li>
              <li>イタリア料理</li>
              <li>ピザ/パスタ</li>
              <li>カフェ（フルサービス）</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/UzNXMzgzaG0O6RbB5rzPYAOtwskHExK2NcVVd9rbOZg=.html"
              company="株式会社ダイゴインターナショナル"
              title="【年収480万円】「焼肉とワイン醍醐」銀座店の料理長候補募集！残業手当全額支給・インセンティブ支給あり・業績賞与あり。評価が給与に還元される環境です！"
              payment="35万円〜40万円"
              location="東京都中央区"
              holiday="月8日休み～月9日休み（週休2日制）、夏季休暇、年末年始、慶弔休暇、有給休暇"
            >
              <li>料理長</li>
              <li>焼肉/ホルモン焼き</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/wxCmcdTCvJLawCw9aU2LtpPNps7kNLFHgyBaKWKVAjE=.html"
              company="株式会社グローバルダイニング"
              title="【年収580万円】白金台の「カフェ・ラ・ボエム」料理長候補の募集！経営者に近い視点で店舗マネジメントを担当して頂きます！"
              payment="43万円〜49万円"
              location="東京都港区"
              holiday="月8日休み（シフト制）、有給休暇（入社6か月後、10日以上）、産休・育児休暇"
            >
              <li>キッチン</li>
              <li>イタリア料理</li>
              <li>カフェ（フルサービス）</li>
              <li>メキシコ料理</li>
              <li>居酒屋</li>
            </TopPickUpItem>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default TopPickUp;
