import type { MetaFunction, LoaderFunctionArgs } from "@remix-run/cloudflare";
import { useEffect } from "react";
import { useScrollToAnchor } from "./components/useScrollToAnchor";
import Header from "./components/Header";
import Footer from "./components/Footer";
import KeyVisual from "./components/top/KeyVisual";
import TopSearch from "./components/top/TopSearch";
import TopPickUp from "./components/top/TopPickUp";
import Logos from "./components/top/Logos";
import SearchByBiz from "./components/top/SearchByBiz";
import SearchByPosition from "./components/top/SearchByPosition";
import SearchByArea from "./components/top/SearchByArea";
import Contact1 from "./components/top/Contact1";
import AboutUs from "./components/top/AboutUs";
import Recommend from "./components/top/Recommend";
import Flow from "./components/top/Flow";
import Contact2 from "./components/top/Contact2";
import Loading from "./components/Loading";

import { useLoaderData } from "@remix-run/react";
import type { Masters } from "~/types";

export const meta: MetaFunction = () => {
  return [
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: "飲食店の正社員求人・転職はエフジョブ求人",
        url: "https://fjob-kyujin.com/",
      },
    },
    { title: "飲食店の正社員求人・転職はエフジョブ求人" },
    {
      name: "description",
      content: "理想の転職を飲食業界のプロと一緒に実現するエフジョブ求人",
    },
    {
      property: "og:title",
      content: "飲食店の正社員求人・転職はエフジョブ求人",
    },
    {
      property: "og:description",
      content: "理想の転職を飲食業界のプロと一緒に実現するエフジョブ求人",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content: "/ogimage.png",
    },
    {
      property: "og:url",
      content: "https://fjob-kyujin.com/",
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
  ];
};

export const loader = async ({ context }: LoaderFunctionArgs) => {
  try {
    // 指定なし系情報を取得タイミングで排除する（search_areasのidの33を除外、occupation_typesのid53を除外）
    // 一覧には表示しない「指定なし」のものを排除
    // 都道府県の「指定なし」は都道府県を画面表示側で使ってないのでここで排除する必要がない
    // occupationTypeCategoriesにも「指定なし」が存在するが、当該情報はmapで回しているわけではないので、ここで排除する必要がない
    // 業態は中間テーブルで求人情報とひも付きを管理するので「指定なし」が存在しない
    const [
      searchAreaCategories,
      searchAreas,
      serviceTypeCategories,
      serviceTypes,
      occupationTypeCategories,
      occupationTypes,
    ] = await Promise.all([
      context.db.search_area_categories.findMany(),
      context.db.search_areas.findMany({
        where: {
          id: {
            not: 33
          }
        },
        orderBy: [
          { search_area_category_id: 'asc' },
          { display_order: 'asc' }
        ]
      }),
      context.db.service_type_categories.findMany(),
      context.db.service_types.findMany({
        orderBy: [
          { service_type_category_id: 'asc' },
          { display_order: 'asc' }
        ]
      }),
      context.db.occupation_type_categories.findMany(),
      context.db.occupation_types.findMany({
        where: {
          id: {
            not: 53
          }
        },
        orderBy: [
          { occupation_type_category_id: 'asc' },
          { display_order: 'asc' }
        ]
      }),
    ]);

    return {
      searchAreaCategories,
      searchAreas,
      serviceTypeCategories,
      serviceTypes,
      occupationTypeCategories,
      occupationTypes,
    };
  } catch (error) {
    console.error("Failed to load masters:", error);
    throw new Response("Internal Server Error", { status: 500 });
  }
};

export default function Index() {
  const { scrollToAnchor } = useScrollToAnchor({ smooth: true });
  useEffect(() => {
    scrollToAnchor();
  }, [scrollToAnchor]);

  const masters = useLoaderData<Masters>();

  return (
    <div>
      <Header page="top"></Header>
      <main>
        <KeyVisual></KeyVisual>
        <TopSearch masters={masters}></TopSearch>
        <TopPickUp></TopPickUp>
        <Logos></Logos>
        <SearchByBiz masters={masters}></SearchByBiz>
        <SearchByPosition masters={masters}></SearchByPosition>
        <SearchByArea masters={masters}></SearchByArea>
        <Contact1 masters={masters}></Contact1>
        <AboutUs></AboutUs>
        <Recommend></Recommend>
        <Flow></Flow>
        <Contact2></Contact2>
      </main>
      <Footer></Footer>
      <Loading></Loading>
    </div>
  );
}
