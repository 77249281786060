import React, { ReactNode } from "react";
import { useInView } from "react-intersection-observer";

type Props = {
  children: ReactNode;
  content: string;
  pointNumber: string;
  imgUrl: string;
};

const RecommendItem: React.FC<Props> = (props) => {
  const { ref, inView } = useInView();

  return (
    <article
      ref={ref}
      className={`pt-4 px-3 pb-5 rounded-lg border-[3px] border-primary shadow-[3px_3px_0_0_#F08300] md:py-6 md:px-8 transition-[transform,opacity] duration-1000 ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"
      }`}
    >
      <div className="flex items-center mb-4 pb-4 bg-[linear-gradient(to_right,#F08300_2px,transparent_2px)] bg-[length:8px_2px] bg-repeat-x bg-left-bottom md:mb-6 md:pb-6">
        <span className="flex-shrink-0 block w-[72px] mr-3 md:w-[24]">
          <figure className="block aspect-square w-full">
            <img src={props.imgUrl} alt="" />
          </figure>
        </span>
        <div className="flex-1">
          <p className="font-candal text-sm text-primary leading-normal md:mb-2 md:text-lg">
            point&nbsp;
            <strong className="text-[28px] md:text-[32px]">
              {props.pointNumber}
            </strong>
          </p>
          <h4 className="text-2xl font-black [&_em]:not-italic [&_em]:font-black [&_em]:text-primary md:text-[32px]">
            {props.children}
          </h4>
        </div>
      </div>
      <div className="text-sm leading-[1.8] font-medium md:text-base md:leading-[1.8]">
        {props.content}
      </div>
    </article>
  );
};

export default RecommendItem;
