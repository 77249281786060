import React from "react";
import { useTransition } from "react-transition-state";

const Loading = () => {
  const [{ status }, toggle] = useTransition({
    timeout: 600,
    preEnter: true,
    unmountOnExit: true,
    initialEntered: true,
  });
  React.useEffect(() => {
    const timer = setTimeout(() => {
      toggle(false);
    }, 600);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className={`fixed inset-0 bg-primary z-50 transition-opacity duration-500 delay-100 opacity-100 ${status} [&.preEnter]:opacity-100 [&.exiting]:opacity-0 [&.unmounted]:opacity-0 [&.unmounted]:hidden`}
    >
      <div className="flex items-center justify-center w-full h-full">
        <span className="block w-[375px] max-w-full max-h-full md:w-[588px]">
          <img src="/loading-logo.webp" width="588" height="730" alt="" />
        </span>
      </div>
    </div>
  );
};

export default Loading;
