const Logos = () => {
  return (
    <section className="my-14 py-8 bg-primary bg-[url('/top/logos-bg_sp.webp')] bg-[length:auto_100%] bg-center bg-no-repeat md:my-[104px] md:py-10 md:bg-[url('/top/logos-bg_pc.webp')]">
      <div className="md:max-w-[1040px] md:mx-auto md:px-7">
        <h3 className="mb-6 text-2xl text-white text-center leading-normal font-black sp:px-4 md:text-[32px] md:leading-normal md:mb-8">
          取引先企業例
        </h3>
      </div>
      <div className="overflow-hidden">
        <ul className="animate-infiniteScroll flex gap-4 w-fit [&_li]:flex-shrink-0 [&_li]:grid [&_li]:gap-4 [&_figure]:block sp:[&_li]:grid-cols-1 sp:[&_li]:w-[123px] sp:[&_figure]:w-[123px] md:[&_li]:w-[336px] md:[&_li]:grid-cols-2 md:[&_figure]:w-[160px]">
          <li>
            <figure>
              <img
                src="/top/logos/logo-teatrino.webp"
                width="160"
                height="78"
                alt="イル テアトリーノ ダ サローネ"
              />
            </figure>
            <figure>
              <img
                src="/top/logos/logo-hankyu.webp"
                width="160"
                height="78"
                alt="阪急阪神第一ホテルグループ"
              />
            </figure>
          </li>
          <li>
            <figure>
              <img
                src="/top/logos/logo-series.webp"
                width="160"
                height="78"
                alt="Chinese restaurant Series"
              />
            </figure>
            <figure>
              <img
                src="/top/logos/logo-baycrews.webp"
                width="160"
                height="78"
                alt="BAYCREW'S GROUP"
              />
            </figure>
          </li>
          <li>
            <figure>
              <img
                src="/top/logos/logo-kohou.webp"
                width="160"
                height="78"
                alt="虎峰"
              />
            </figure>
            <figure>
              <img
                src="/top/logos/logo-sasaya.webp"
                width="160"
                height="78"
                alt="SASAYAホールディングス"
              />
            </figure>
          </li>
          <li>
            <figure>
              <img
                src="/top/logos/logo-db.webp"
                width="160"
                height="78"
                alt="ドミニク・ブシェ トーキョー"
              />
            </figure>
            <figure>
              <img
                src="/top/logos/logo-tanta.webp"
                width="160"
                height="78"
                alt="トラットリア タンタボッカ"
              />
            </figure>
          </li>
          <li>
            <figure>
              <img
                src="/top/logos/logo-liaison.webp"
                width="160"
                height="78"
                alt="LIASON Cuisine Francaise"
              />
            </figure>
            <figure>
              <img
                src="/top/logos/logo-latte.webp"
                width="160"
                height="78"
                alt="LATTE GRAPHIC"
              />
            </figure>
          </li>
          <li>
            <figure>
              <img
                src="/top/logos/logo-ldh.webp"
                width="160"
                height="78"
                alt="LDH"
              />
            </figure>
            <figure>
              <img
                src="/top/logos/logo-mercer.webp"
                width="160"
                height="78"
                alt="マーサーブランチ"
              />
            </figure>
          </li>
          <li>
            <figure>
              <img
                src="/top/logos/logo-transit.webp"
                width="160"
                height="78"
                alt="TRANSIT GENERAL OFFICE INC."
              />
            </figure>
            <figure>
              <img
                src="/top/logos/logo-oreno.webp"
                width="160"
                height="78"
                alt="俺の株式会社"
              />
            </figure>
          </li>
          <li>
            <figure>
              <img
                src="/top/logos/logo-global.webp"
                width="160"
                height="78"
                alt="株式会社グローバルダイニング"
              />
            </figure>
            <figure>
              <img
                src="/top/logos/logo-dd.webp"
                width="160"
                height="78"
                alt="DDホールディングス"
              />
            </figure>
          </li>
          <li>
            <figure>
              <img
                src="/top/logos/logo-mihaku.webp"
                width="160"
                height="78"
                alt="株式会社mihaku"
              />
            </figure>
            <figure>
              <img
                src="/top/logos/logo-osho.webp"
                width="160"
                height="78"
                alt="餃子の王将"
              />
            </figure>
          </li>
        </ul>
        <div className="mt-4 px-4 md:px-7">
          <p className="text-xs text-white leading-normal md:max-w-[1040px] md:mx-auto md:text-center md:text-sm md:leading-normal">
            *上記はこれまで取引実績のある企業のため、現在も求人を募集している企業とは限りません
          </p>
        </div>
      </div>
    </section>
  );
};

export default Logos;
