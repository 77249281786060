import React from "react";

type Props = {
  text: string;
  icon: string;
  color: string;
  handler: React.MouseEventHandler<HTMLButtonElement>;
};

const TopSearchButton: React.FC<Props> = (props) => {
  return (
    <button
      onClick={props.handler}
      className={`group inline-block p-2 bg-white rounded-lg shadow-[2px_2px_6px_0_rgba(51,51,51,0.25)] border-2 border-white ${props.color} hover:[&.primary]:border-primary hover:[&.secondary]:border-secondary hover:[&.tertiary]:border-tertiary`}
    >
      <span className="md:flex md:items-center md:justify-center">
        <span
          className={`flex-shrink-0 flex items-center justify-center w-9 h-9 rounded-full md:w-10 md:h-10 group-[.primary]:bg-primary group-[.secondary]:bg-secondary group-[.tertiary]:bg-tertiary sp:mx-auto md:mr-2.5`}
        >
          <svg className="block w-6 h-6 fill-white md:w-7 md:h-7 group-[.secondary]:translate-y-[2px] group-[.primary]:scale-[0.9]">
            <use xlinkHref={`/symbol-defs.svg#icon-${props.icon}`}></use>
          </svg>
        </span>
        <span
          className={`block mt-1 font-bold group-[.primary]:text-primary group-[.secondary]:text-secondary group-[.tertiary]:text-tertiary`}
        >
          <span className="block font-bold text-xs leading-tight md:text-base">
            <strong className="block text-lg leading-tight font-bold md:text-[22px] lg:inline">
              {props.text}
            </strong>
            を選択
          </span>
        </span>
      </span>
    </button>
  );
};

export default TopSearchButton;
