import React, { ReactNode } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

type Props = {
  children: ReactNode;
};

const SimpleBarWrap: React.FC<Props> = (props) => {
  const scrollableNodeRef = React.createRef();

  return (
    <>
      <div className="h-[272px] md:hidden">
        <SimpleBar
          className="sp:max-w-full sp:overflow-y-hidden sp:[&_.simplebar-scrollbar:before]:inset-0 sp:[&_.simplebar-scrollbar.simplebar-visible:before]:opacity-100 sp:[&_.simplebar-scrollbar:before]:bg-primary sp:[&_.simplebar-track.simplebar-horizontal]:mx-4 sp:[&_.simplebar-track.simplebar-horizontal]:h-1 sp:[&_.simplebar-track.simplebar-horizontal]:rounded sp:[&_.simplebar-track.simplebar-horizontal]:bg-gray/25 md:[&_div]:!overflow-visible"
          forceVisible="x"
          autoHide={false}
          scrollableNodeProps={{ ref: scrollableNodeRef }}
        >
          {props.children}
        </SimpleBar>
      </div>
      <div className="hidden md:block">{props.children}</div>
    </>
  );
};

export default SimpleBarWrap;
