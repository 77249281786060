import RecommendItem from "./RecommendItem";

const Recommend = () => {
  return (
    <section className="my-14 px-4 md:my-[104px] md:px-7">
      <div className="max-w-[1040px] mx-auto">
        <div className="flex items-center justify-center md:justify-start">
          <svg className="block w-5 h-5 mr-1 fill-primary md:w-6 md:h-6">
            <use xlinkHref="/symbol-defs.svg#icon-egg"></use>
          </svg>
          <p className="text-primary text-sm font-candal tracking-[0.06em] md:text-lg md:tracking-[0.06em]">
            Recommendation
          </p>
        </div>
        <h2 className="mb-4 text-center text-[28px] font-black leading-normal md:mb-6 md:text-left md:text-[46px] md:leading-normal">
          『エフジョブ求人』の
          <br className="md:hidden" />
          <span className="text-primary">オススメポイント</span>
        </h2>
        <p className="mb-6 text-[15px] leading-[1.8] md:mb-10 md:text-base md:leading-[1.8]">
          飲食業界への転職なら『エフジョブ求人』におまかせください！
        </p>
        <div className="grid grid-cols-1 gap-5">
          <RecommendItem
            imgUrl="/top/recommend-icon-1.webp"
            pointNumber="01"
            content="飲食業界の求人のみを1万件以上掲載。業界特化型だから、業態、職種の検索条件も細かく設定が可能です！"
          >
            <em>飲食業界の求人情報のみ</em>を掲載
          </RecommendItem>
          <RecommendItem
            imgUrl="/top/recommend-icon-2.webp"
            pointNumber="02"
            content="キャリアアドバイザーは飲食業界経験者や国家資格である「キャリアコンサルタント」の有資格者、業界経験10年以上のベテランまで。飲食業界と転職活動を熟知したプロが、あなたの転職をサポートします！"
          >
            業界に精通した<em>プロ</em>が<em>多数在籍</em>
          </RecommendItem>
          <RecommendItem
            imgUrl="/top/recommend-icon-3.webp"
            pointNumber="03"
            content="職務経歴書・履歴書の作成から年収交渉、面接の練習まで。転職活動の開始から内定まで、どんな些細なことでもサポートいたします！「まだ転職は考えてないんだけど、最近の転職市場の状況だけでも知りたい。」「もしかして今の給与は不当に安いのでは……？」というような相談にもお応えいたします。転職活動が初めての方もお気軽にお問い合わせください。"
          >
            <em>充実</em>の<em>サポート</em>内容
          </RecommendItem>
          <RecommendItem
            imgUrl="/top/recommend-icon-4.webp"
            pointNumber="04"
            content="面談日程や時間、面談方法など可能な限り転職希望者様のご都合に合わせた豊富な対応方法をご用意しております。"
          >
            ご都合に合わせた<em>柔軟な</em>サポート体制
          </RecommendItem>
          <RecommendItem
            imgUrl="/top/recommend-icon-5.webp"
            pointNumber="05"
            content="お問い合わせはもちろん、転職活動中から内定まで、『エフジョブ求人』のサービスは全て無料で受けられます。お気軽にお問い合わせください。"
          >
            料金は<em>完全無料</em>
          </RecommendItem>
        </div>
      </div>
    </section>
  );
};

export default Recommend;
