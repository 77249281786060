import React, { ReactNode } from "react";

type Props = {
  imgUrl: string;
  color: string;
  titleStrong: string;
  titleSmall: string;
  children: ReactNode;
  notice?: string;
};
const Contact1Item: React.FC<Props> = (props) => {
  return (
    <article
      className={`group ${props.color} rounded-lg bg-white overflow-hidden md:flex`}
    >
      <figure className="relative block sp:aspect-[343/240] md:w-[44%] md:min-h-[268px]">
        <span className="absolute inset-0">
          <img
            src={props.imgUrl}
            alt=""
            className="w-full h-full object-cover object-top"
          />
        </span>
      </figure>
      <div className="p-5 md:w-[56%] md:grid md:items-center md:pt-8 md:pb-5 md:px-6 lg:px-10">
        <div>
          <h3 className="mb-3 -indent-3 md:-indent-4">
            <strong
              className={`font-black text-2xl leading-normal md:text-[28px] md:leading-normal group-[.primary]:text-primary group-[.green]:text-green`}
            >
              {props.titleStrong}
            </strong>
            <br className="sp:hidden" />
            <small className="font-black text-lg leading-normal md:text-[22px] md:leading-normal">
              {props.titleSmall}
            </small>
          </h3>
          <div className="font-medium [&_p]:text-sm [&_p]:leading-[1.8] md:[&_p]:leading-normal">
            {props.children}
          </div>
          <div className="text-center"></div>
          <p className="mt-2 text-center text-xs">{props.notice}</p>
        </div>
      </div>
    </article>
  );
};

export default Contact1Item;
