import React from "react";

type Props = {
  text: string;
  icon: string;
  iconBg: string;
};

const TopHeading: React.FC<Props> = (props) => {
  return (
    <h2>
      <span className="flex items-center">
        <span
          className={`flex-shrink-0 flex items-center justify-center w-12 aspect-square rounded-full mr-3 md:w-14 md:mr-4 ${props.iconBg} [&.primary]:bg-primary [&.secondary]:bg-secondary [&.tertiary]:bg-tertiary`}
        >
          <svg className="block w-7 h-7 fill-white md:w-8 md:h-8">
            <use xlinkHref={`/symbol-defs.svg#icon-${props.icon}`}></use>
          </svg>
        </span>
        <span className="flex-1 text-2xl font-black md:text-[32px]">
          {props.text}
        </span>
      </span>
    </h2>
  );
};

export default TopHeading;
