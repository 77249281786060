import React, { ReactNode } from "react";
import { useInView } from "react-intersection-observer";

type Props = {
  stepNumber: string;
  stepTitle: string;
  children: ReactNode;
};

const FlowItem: React.FC<Props> = (props) => {
  const { ref, inView } = useInView();

  return (
    <div
      ref={ref}
      className={`group transition-[opacity,transform] duration-1000 ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"
      }`}
    >
      <div className="md:flex md:items-center">
        <div className="relative flex items-center justify-center w-[68px] mx-auto aspect-square rounded-full bg-primary text-white text-center md:flex-shrink-0 md:w-[96px] md:mr-[-56px]">
          <p className="font-candal">
            <span className="block text-xs leading-none md:text-lg md:leading-none">
              STEP
            </span>
            <span className="block -mt-0.5 text-2xl leading-none md:text-[32px] md:leading-none">
              {props.stepNumber}
            </span>
          </p>
        </div>
        <dl className="pt-10 px-3 pb-3 border-[3px] border-primary rounded bg-white sp:mt-[-34px] md:flex-1 md:py-6 md:pr-8 md:pl-[85px]">
          <dt className="mb-1 text-2xl text-primary text-center font-black md:mb-2 md:text-left md:text-[32px] md:leading-[1.8]">
            {props.stepTitle}
          </dt>
          <dd className="text-sm leading-[1.8] md:text-base md:leading-[1.8]">
            {props.children}
          </dd>
        </dl>
      </div>
      <div className="group-last:hidden mx-auto w-0 h-0 border-t-[20px] border-r-[21.5px] border-b-0 border-l-[21.5px] border-[#F08300_transparent_transparent_transparent] md:border-t-[30px] md:border-r-[34px] md:border-l-[34px]"></div>
    </div>
  );
};

export default FlowItem;
