import React from "react";
import { useNavigate } from "react-router-dom";
import TopSearchButton from "./TopSearchButton";
import MediumButton from "../MediumButton";
import LabelDeletable from "../LabelDeletable";
import SearchModal from "../search/SearchModal";
import { useTransition } from "react-transition-state";
import type { Masters, Condition } from "~/types";

type Props = { masters: Masters };

// TODO: 冗長性排除
const initialState: Condition = {
  occupation_type_ids: [],
  search_area_ids: [],
  service_type_ids: [],
};

const TopSearch: React.FC<Props> = (props) => {
  const masters = props.masters;
  const [visible, setVisible] = React.useState(false);
  const [number, setNumber] = React.useState(0);
  const [selectedConditions, setSelectedConditions] =
    React.useState<Condition>(initialState);
  const [isSearching, setIsSearching] = React.useState(false);
  const navigate = useNavigate();

  // モーダル開閉アニメーション用
  const [{ status }, transitionToggle] = useTransition({
    timeout: 1,
    preEnter: true,
  });

  const stateToggle0 = () => {
    setVisible(!visible);
    setNumber(0);
    transitionToggle(true);
  };

  const stateToggle1 = () => {
    setVisible(!visible);
    setNumber(1);
    transitionToggle(true);
  };

  const stateToggle2 = () => {
    setVisible(!visible);
    setNumber(2);
    transitionToggle(true);
  };

  const handleVisibleToFalse = () => {
    transitionToggle(false);
    setTimeout(() => {
      setVisible(false);
    }, 1000);
  };

  // 検索条件の追加
  const handleAddCondition = (
    type: keyof Condition,
    value: string | string[]
  ) => {
    setSelectedConditions((prevConditions) => {
      const newValue = Array.isArray(value) ? value : [value];
      const updateValue = newValue.filter(
        (id) => !prevConditions[type].includes(id)
      );
      return {
        ...prevConditions,
        [type]: [...prevConditions[type], ...updateValue],
      };
    });
  };

  // 検索条件の削除
  const handleSubCondition = (
    type: keyof Condition,
    value: string | string[]
  ) => {
    setSelectedConditions((prevConditions) => {
      const newValues = Array.isArray(value) ? value : [value];
      return {
        ...prevConditions,
        [type]: prevConditions[type].filter((id) => !newValues.includes(id)),
      };
    });
  };

  // 検索条件のリセット
  const handleResetConditions = () => {
    setSelectedConditions(initialState);
  };

  // クエリパラメーターを生成
  const generateQueryParams = (conditions: Condition) => {
    const params = new URLSearchParams();
    Object.keys(conditions).forEach((key) => {
      const value = conditions[key as keyof Condition];
      if (value.length > 0) {
        params.append(key, value.join(","));
      }
    });
    return params.toString();
  };

  // 検索ボタンをクリックしたときの処理
  const handleSearchClick = () => {
    setIsSearching(true);
    const queryParams =
      JSON.stringify(selectedConditions) === JSON.stringify(initialState)
        ? ""
        : `?${generateQueryParams(selectedConditions)}`;

    navigate(`/list${queryParams}`);
    setIsSearching(false);
  };

  return (
    <>
      <section className="relative z-10 -mt-4 mb-16 px-4 drop-shadow-lg md:-mt-14 md:mb-18 md:px-7">
        <div className="relative max-w-[400px] mx-auto px-3 py-8 rounded-2xl bg-primary-light z-10 overflow-hidden md:max-w-[1040px] md:px-6 lg:px-14 md:pt-6 md:pb-9 md:rounded-tr-lg md:rounded-tl-lg">
          <div className="absolute -right-5 -top-5 w-[100px] md:top-[-60px] md:right-[-36px] md:w-[233px]">
            <img src="/top/search-bg-1.webp" width="233" height="289" alt="" />
          </div>
          <div className="absolute -left-4 -bottom-1 w-[90px] md:bottom-[-70px] md:left-[144px] md:w-[144px]">
            <img src="/top/search-bg-2.webp" width="144" height="165" alt="" />
          </div>
          <div className="relative">
            <div className="text-center">
              <h2 className="relative inline-block mb-4 text-sm leading-normal text-primary font-bold before:absolute before:-left-2 before:bottom-[5px] before:w-0.5 before:h-4 before:rounded before:bg-primary before:origin-bottom-right before:rotate-[-40deg] after:absolute after:-right-1 after:bottom-[5px] after:w-0.5 after:h-4 after:rounded after:bg-primary after:origin-bottom-left after:rotate-[40deg] md:text-base md:leading-normal md:before:-left-4 md:before:w-[3px] md:before:h-5 md:after:-right-2.5 md:after:w-[3px] md:after:h-5">
                希望する条件から求人を探せます！
              </h2>
            </div>
            <div className="md:grid md:grid-cols-[1fr_210px] md:items-center md:gap-4 lg:gap-7">
              <div className="grid grid-cols-3 gap-3 md:gap-2 lg:gap-[14px]">
                <TopSearchButton
                  text="業態"
                  color="secondary"
                  icon="bowl"
                  handler={stateToggle0}
                ></TopSearchButton>
                <TopSearchButton
                  text="職種"
                  color="primary"
                  icon="shirt"
                  handler={stateToggle1}
                ></TopSearchButton>
                <TopSearchButton
                  text="エリア"
                  color="tertiary"
                  icon="pin"
                  handler={stateToggle2}
                ></TopSearchButton>
              </div>
              {/* PC用[この条件で検索する]ボタン start */}
              <div className="sp:hidden text-center max-w-[210px] [&_button]:w-full [&_button]:px-1 [&_button]:py-4 [&_button]:text-base">
                <MediumButton
                  text="この条件で検索する"
                  handler={handleSearchClick}
                  disabled={isSearching}
                ></MediumButton>
              </div>
              {/* PC用[この条件で検索する]ボタン end */}
            </div>
            {Object.keys(selectedConditions).some(
              (key) => selectedConditions[key as keyof Condition].length > 0
            ) && (
              <dl className="mt-4 pt-4 px-2 pb-2 text-center rounded-lg bg-white shadow-[2px_2px_6px_0_rgba(51,51,51,0.25)] md:pt-2 md:pb-3">
                <dt className="mb-2 font-bold text-base text-primary leading-[1.8] md:mb-1 md:text-lg md:leading-[1.8]">
                  選択した条件
                </dt>
                <dd className="[&>div]:m-2 md:[&>div]:m-1">
                  {Object.keys(selectedConditions).map((key) => {
                    const labelColor =
                      key === "occupation_type_ids"
                        ? "primary"
                        : key === "service_type_ids"
                        ? "secondary"
                        : key === "search_area_ids"
                        ? "tertiary"
                        : "default";
                    return selectedConditions[key as keyof Condition].map(
                      (id) => {
                        let text = id;
                        if (key === "occupation_type_ids") {
                          text =
                            masters.occupationTypes.find(
                              (item) => item.id === id
                            )?.name || id;
                        } else if (key === "service_type_ids") {
                          text =
                            masters.serviceTypes.find((item) => item.id === id)
                              ?.name || id;
                        } else if (key === "search_area_ids") {
                          text =
                            masters.searchAreas.find((item) => item.id === id)
                              ?.name || id;
                        }
                        return (
                          <LabelDeletable
                            key={id}
                            text={text}
                            labelColor={labelColor}
                            handleSubCondition={handleSubCondition}
                            type={key as keyof Condition}
                            value={id}
                          />
                        );
                      }
                    );
                  })}
                </dd>
              </dl>
            )}
            {/* SP用[この条件で検索する]ボタン start */}
            <div className="md:hidden mt-4 text-center max-w-[280px] mx-auto [&_button]:w-full">
              <MediumButton
                text="この条件で検索する"
                handler={handleSearchClick}
                disabled={isSearching}
              ></MediumButton>
            </div>
            {/* SP用[この条件で検索する]ボタン start */}
          </div>
        </div>
        <div className="relative max-w-[400px] h-3 mx-auto -mt-3 bg-[linear-gradient(90deg,rgba(240,101,0,1)_0%,rgba(236,170,0,1)_100%)] rounded-bl-2xl rounded-br-2xl mix-blend-multiply z-10 md:max-w-[1040px]">
          <div className="bg-white h-3 translate-y-[-6px] rounded-md md:translate-y-[-8px]"></div>
        </div>
      </section>
      <div className={`group ${status}`}>
        {visible && (
          <SearchModal
            setVisible={setVisible}
            activeTab={number}
            masters={masters}
            handleAddCondition={handleAddCondition}
            handleSubCondition={handleSubCondition}
            handleResetConditions={handleResetConditions}
            handleVisibleToFalse={handleVisibleToFalse}
            selectedConditions={selectedConditions}
            setSelectedConditions={setSelectedConditions}
          ></SearchModal>
        )}
      </div>
    </>
  );
};

export default TopSearch;
